import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [0,2];

export const dictionary = {
		"/[[locale]]/about-us": [4,[2]],
		"/[[locale]]/admin": [5,[2]],
		"/[[locale]]/allianz": [~6,[2]],
		"/api/payment-intent/validate": [~36],
		"/[[locale]]/axa-policy-check": [~8,[2]],
		"/[[locale]]/axa": [~7,[2]],
		"/[[locale]]/become-a-partner": [9,[2]],
		"/[[locale]]/blog": [~10,[2]],
		"/[[locale]]/blog/author/[slug]": [~12,[2]],
		"/[[locale]]/blog/category/[slug]": [~13,[2]],
		"/[[locale]]/blog/search": [14,[2]],
		"/[[locale]]/blog/tags/[slug]": [~15,[2]],
		"/[[locale]]/blog/[slug]": [~11,[2]],
		"/[[locale]]/confirmation/[cart_id]": [~16,[2]],
		"/[[locale]]/cta-americas": [~17,[2]],
		"/[[locale]]/embassies-consulates": [18,[2]],
		"/[[locale]]/extend/[cart_id]": [~19,[2]],
		"/[[locale]]/extend/[cart_id]/expired": [20,[2]],
		"/[[locale]]/extend/[cart_id]/link-expired": [21,[2]],
		"/[[locale]]/extend/[cart_id]/payment": [~22,[2]],
		"/header": [37],
		"/[[locale]]/healix-policy-check": [~23,[2]],
		"/[[locale]]/login": [24,[2]],
		"/[[locale]]/mutuaide-policy-check": [~26,[2]],
		"/[[locale]]/mutuaide": [~25,[2]],
		"/[[locale]]/payment/[cart_id]": [~27,[2]],
		"/[[locale]]/quotation/[cart_id]": [~28,[2]],
		"/[[locale]]/redirect": [29,[2]],
		"/[[locale]]/schengen-travel-insurance": [~30,[2]],
		"/test": [38],
		"/[[locale]]/travel-insurance-australia": [~31,[2]],
		"/[[locale]]/travel-insurance-uk": [~32,[2]],
		"/[[locale]]/travel-medical-insurance": [~33,[2]],
		"/[[locale]]/visitors-insurance": [~34,[2]],
		"/[[locale]]/your-details/[cart_id]": [~35,[2]],
		"/zendesk": [39],
		"/[[locale]]": [~3,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';